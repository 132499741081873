// Import from NPM
// -------------------------------------
import _ from "lodash";

// Import from Config
// -------------------------------------
import { Div, Image } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------

export default function SocialLogin(props) {
    // ========================= Render Function =================================
    return (
        <Div padTop flex column gapped clickable>
            {settings.socialauth.length > 0 &&
                _.map(settings.socialauth, (btn, idx) => {
                    return (
                        <Image
                            key={`social-auth-${idx}`}
                            fluid
                            src={btn.image}
                            onClick={() => {
                                const redirectUrl = btn.url
                                    ? settings.baseUrl + btn.url
                                    : null;
                                if (redirectUrl) {
                                    window.location.href = redirectUrl;
                                }
                            }}
                        />
                    );
                })}
        </Div>
    );
}
