import { Helmet } from "react-helmet";
import { useFeatures } from "flagged";
import { useGetBrandingQuery } from "@api/apiV6";

import "@styles/layouts.scss";
import { Div } from "@components/Generics.react";
import { darkStyle } from "@utilities/helpers";
import { settings } from "@config/settings/app.settings";
export default function AuthLayout(props) {
    const { title, children, team, teamLogin = false } = props;
    const storedTeam = window.localStorage.getItem("team");
    const { data } = useGetBrandingQuery(team || storedTeam);
    const features = useFeatures();

    const branding =
        features.tenanting.multiTenanted && data
            ? {
                  color: data.colors?.main || settings.colors.main,
                  logo: data.image,
              }
            : {
                  color: settings.colors.main,
                  logo: "/assets/images/configurable/logo-full.png",
              };

    // ========================= Render Function =================================
    return (
        <Div fluid ivory>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Div fluid pageht flex column style={darkStyle(branding.color)}>
                {teamLogin ? (
                    <Div fluid fullht>
                        {children}
                    </Div>
                ) : (
                    <Div wd="360px" flex white superpad rounded dropShadow>
                        <Div clear fitted fluid center-align>
                            <br />
                            {children}
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
}
