// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { Loader, Image, Button, Divider } from "semantic-ui-react";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFeatures } from "flagged";
import localforage from "localforage";

// Import from Config
// -------------------------------------
import { login } from "@reducers/authSlice";
import { apiV6, baseUrl } from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";
import { devMode } from "@utilities/helpers";
import AuthLayout from "@layouts/AuthLayout.react";

// Import Components
// -------------------------------------

export default function AutoLogin(props) {
    const { team, token, setToken, branding, authPageLinks } = props;
    const dispatch = useDispatch();
    const features = useFeatures();
    const { t } = useTranslation("common");

    const [error, setError] = useState(null);

    const wPageHt = window.innerHeight - 100;
    const isPortrait = window.innerHeight > window.innerWidth;

    useEffect(() => {
        (async () => {
            if (settings.functionality.guestsActive && token === "goGuest") {
                window.location.href = `/guest/login${
                    features.tenanting.autoGuests ? "?auto=true" : ""
                }`;
            } else {
                await tokenLogin();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const tokenLogin = async () => {
        const urls = [`${baseUrl}api/v3/auth/sign_in`, `${baseUrl}api/v2/smollan/auth/connect`];
        let response;
        try {
            for (const url of urls) {
                try {
                    response = await axios({
                        method: "post",
                        url,
                        headers: { authtoken: token },
                    });
                    break; // Exit loop if request succeeds
                } catch (error) {
                    console.warn(`Failed to authenticate with ${url}`);
                }
            }
            let decryptResponse = JSON.parse(
                CryptoJS.AES.decrypt(response.data, "16t17t5m16t17t5m").toString(
                    CryptoJS.enc.Utf8
                )
            );
            
            let brandingAdjustedUser = _.cloneDeep(decryptResponse);

            // Set default branding if undefined or null
            brandingAdjustedUser.branding = brandingAdjustedUser.branding || {};
            brandingAdjustedUser.branding.colors = brandingAdjustedUser.branding
            .colors || {
                main: settings.colors.main,
                primary: settings.colors.primary,
                primaryText: settings.colors.primaryText,
            };
            if (team != null)
                window.localStorage.setItem("team", decryptResponse.team);

            await dispatch(apiV6.util.resetApiState());
            await dispatch(
                login({
                    user: brandingAdjustedUser,
                    token: response.headers["access-token"],
                })
            );
            await dispatch(apiV6.endpoints.getArticles.initiate());
            await dispatch(apiV6.endpoints.getCourseLibrary.initiate());

            let redirectPath = localStorage.getItem("redirectPath");
            if (redirectPath) {
                redirectPath =
                    redirectPath !== "/" && redirectPath !== "/logout"
                        ? `${
                              devMode ? "" : settings.targetRoute.slice(0, -1)
                          }${redirectPath}`
                        : "/";
            }
            const redirectUrl = redirectPath || "/";
            localStorage.removeItem("redirectPath");
            window.location.href = redirectUrl;
        } catch (error) {
            setError("Invalid Token Provided!");
            console.log(error);
        }
    }; 

    const clearCache = () => {
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            for (let name of names) caches.delete(name);
        });
        localforage.clear();
        window.location.reload(true);
    };

    const screenView = () => {
        if (error)
            return (
                <Div center-align large>
                    <Div gutter>{error}</Div>
                    <Button
                        negative
                        onClick={clearCache}
                        content={t("auth.loginPage.retry")}
                    />
                </Div>
            );
        else return <Loader active>Logging in...</Loader>;
    };

    // ========================= Render Function =================================
    return (
        <AuthLayout
            title={`Login to ${t("appName")}`}
            team={team}
            teamLogin={true}
        >
            <Div
                fluid
                superpad
                ht={isPortrait ? wPageHt : "100%"}
                flex
                centered
                maxWd="480px"
                key="login-box"
            >
                <Div wd="60%" gutter>
                    <Image src={branding?.image} />
                </Div>
                <Div fluid white fullHt superpad rounded>
                    {screenView()}
                    <Divider />
                    <Div
                        txtMain
                        center-align
                        clickable
                        onClick={() => setToken(null)}
                    >
                        {t(`auth.authLinks.upass`)}
                    </Div>
                    {authPageLinks}
                </Div>
            </Div>
        </AuthLayout>
    );
}
