// Import from NPM
// -------------------------------------
import React from "react";

// Import from Config
// -------------------------------------
import { Button } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------

export default function SSOLogin(props) {
    const omniauthData = settings.omniauth;
    const shouldRenderOmniauthButton =
        omniauthData &&
        omniauthData.length > 0 &&
        omniauthData[0].url &&
        omniauthData[0].text;
    const handleOmniauthClick = (url) => {
        window.location.href = url;
    };

    // ========================= Render Function =================================
    return shouldRenderOmniauthButton ? (
        <Button
            fluid
            icon
            labelPosition="left"
            onClick={() => handleOmniauthClick(omniauthData[0].url)}
        >
            <i className={`icon ${omniauthData[0].icon || "users"}`} />
            {omniauthData[0].text}
        </Button>
    ) : (
        <div />
    );
}
