// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

import { Input, Button, Icon, Checkbox } from "semantic-ui-react";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------

export default function UpassLogin(props) {
    const { loginAction, setError } = props;
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const { t } = useTranslation("common");

    const tryLogin = async () => {
        try {
            let formData = new FormData();
            const SECRET_KEY = '16t17t5m16t17t5m';
            function encrypt(data) {
                try {
                    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
                    
                    // Encrypt the data using AES in ECB mode with PKCS7 padding
                    const encrypted = CryptoJS.AES.encrypt(data, key, {
                    mode: CryptoJS.mode.ECB,
                    padding: CryptoJS.pad.Pkcs7
                });

                    // Return the Base64-encoded encrypted string
                    return encrypted.toString();
                } catch (error) {
                    console.error('Error during encryption: ', error);
                    return null;
                }
            };
            const qUsername = encrypt(username);
            const qPassword = encrypt(password);
            // let qUsername = CryptoJS.AES.encrypt(username, "16t17t5m");
            // let qPassword = CryptoJS.AES.encrypt(password, "16t17t5m");

            formData.append("username", qUsername);
            formData.append("password", qPassword);
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/sign_in`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            loginAction(response);
        } catch (error) {
            console.log(error);
            setError(error.response?.data?.message);
        }
    };

    const loginBox = (
        <>
            <Div massive altText fluid center-align gutter>
                {t("auth.loginPage.pageLabel")}
            </Div>
            <Div fluid gutter>
                <Input
                    fluid
                    label={t("auth.loginPage.usernameLabel")}
                    placeholder={t("auth.loginPage.usernamePlaceholder")}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <br />
                <Input
                    fluid
                    label={t("auth.loginPage.password")}
                    type={showPassword ? "text" : "password"}
                    placeholder={t("auth.loginPage.passwordPlaceholder")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    icon={
                        <Icon
                            name={showPassword ? "eye slash" : "eye"}
                            link
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    }
                />
                <br />
                {settings.functionality.hasTerms && (
                    <Div flex fluid gutter>
                        <Checkbox
                            label={
                                <label>
                                    {t("auth.terms.termsAndConditions")}{" "}
                                    <a
                                        href={t("auth.terms.link")}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t("auth.terms.linkText")}
                                    </a>
                                </label>
                            }
                            checked={termsChecked}
                            onChange={() => {
                                setTermsChecked(!termsChecked);
                            }}
                        />
                    </Div>
                )}
                <Button
                    fluid
                    primary
                    content={t("auth.login")}
                    disabled={settings.functionality.hasTerms && !termsChecked}
                    onClick={tryLogin}
                />
                <br />
                {settings.functionality.smtpActive && (
                    <NavLink to="/forgot">{t("auth.forgot")}</NavLink>
                )}
            </Div>
        </>
    );
    // ========================= Render Function =================================
    return loginBox;
}
