// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFeatures } from "flagged";
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
    NavLink,
} from "react-router-dom";
import { useGetBrandingQuery } from "@api/apiV6";
import abilityMap from "@config/features/ability.json";
import { apiV6 } from "@api/apiV6";

import _ from "lodash";
import { Image, Divider, Button } from "semantic-ui-react";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";
import Carousel from "@components/Carousel.react";
import { darkStyle } from "@utilities/helpers";

// Import from Config
// -------------------------------------
import AuthLayout from "@layouts/AuthLayout.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { login } from "@reducers/authSlice";
import UpassLogin from "./panels/UpassLogin.react";
import OTPLogin from "./panels/OTPLogin.react";
import SocialLogin from "./panels/SocialLogin.react";
import SSOLogin from "./panels/SSOLogin.react";
import AutoLogin from "./panels/AutoLogin.react";

export default function Login(props) {
    const { team } = useParams();
    const { data: branding } = useGetBrandingQuery(team || "Company");

    const [loginPanel, setLoginPanel] = useState(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const features = useFeatures();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { state } = useLocation();

    const [search] = useSearchParams();
    const [token, setToken] = useState(search.get("token"));
    const wPageHt = window.innerHeight - 100;
    const isPortrait = window.innerHeight > window.innerWidth;

    const loginAction = async (response) => {
        let decryptResponse = JSON.parse(
            CryptoJS.AES.decrypt(response.data, "16t17t5m16t17t5m").toString(
                CryptoJS.enc.Utf8
            )
        );
        let brandingAdjustedUser = _.cloneDeep(decryptResponse);
        // Set default branding if undefined or null
        brandingAdjustedUser.branding = brandingAdjustedUser.branding || {};
        brandingAdjustedUser.branding.colors = brandingAdjustedUser.branding
            .colors || {
            main: settings.colors.main,
            primary: settings.colors.primary,
            primaryText: settings.colors.primaryText,
        };
        await dispatch(apiV6.util.resetApiState());
        await dispatch(
            login({
                user: brandingAdjustedUser,
                token: response.headers["access-token"],
            })
        );
        await dispatch(apiV6.endpoints.getArticles.initiate());
        await dispatch(apiV6.endpoints.getCourseLibrary.initiate());

        window.localStorage.setItem("firstLogin", "done");
        if (team != null)
            window.localStorage.setItem("team", decryptResponse.team);
        setTimeout(
            () =>
                navigate(
                    !isPortrait &&
                        features.general.builderRouting &&
                        _.includes(
                            abilityMap.builder.visible,
                            decryptResponse.license.license_type
                        )
                        ? "/builder"
                        : state?.from?.pathname || "/"
                ),
            0
        );
    };

    useEffect(() => {
        (async () => {
            if (search && search.get("token") != null) {
                setToken(search.get("token"));
            } else setToken(null);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const loginOptions = {
        upass: UpassLogin,
        otp: OTPLogin,
        social: SocialLogin,
        sso: SSOLogin,
    };
    const secondaryOptions = ["social", "sso"];

    const PrimaryLogin = loginOptions[loginPanel || features.auth.primary];
    const secondaryLogins = (
        <Div>
            {_.map(secondaryOptions, (o, n) => {
                const LoginElm = loginOptions[o];
                return (
                    <LoginElm
                        key={`sec-login-${n}`}
                        loginAction={loginAction}
                        setError={setError}
                    />
                );
            })}
        </Div>
    );
    const authPageLinks = _.map(
        _.filter(
            _.keys(
                _.omit(
                    features.auth,
                    _.flatten([
                        "primary",
                        "inlineBtns",
                        "signInOrUp",
                        features.auth.inlineBtns && secondaryOptions,
                        loginPanel == null ? features.auth.primary : loginPanel,
                    ])
                )
            ),
            (k) => features.auth[k]
        ),
        (k) => (
            <Div
                key={`auth-link-${k}`}
                txtMain
                center-align
                clickable
                onClick={() => setLoginPanel(k)}
            >
                {t(`auth.authLinks.${k}`)}
            </Div>
        )
    );
    const loginBox = (
        <Div>
            <Div
                fluid
                superpad
                ht={isPortrait ? wPageHt : "100%"}
                flex
                centered
                maxWd="480px"
                key="login-box"
            >
                <Div wd="60%" gutter>
                    <Image src={branding?.image} />
                </Div>
                <Div fluid white fullHt superpad rounded>
                    {error && (
                        <Div padded fluid danger gutter>
                            {error}
                        </Div>
                    )}
                    <PrimaryLogin
                        loginAction={loginAction}
                        setError={setError}
                    />
                    {features.auth.inlineBtns && secondaryLogins}
                    {settings.functionality.registrationActive && (
                        <Div center-align>
                            <Divider horizontal>Or</Divider>
                            <Button.Group>
                                <NavLink
                                    to={
                                        team == null
                                            ? "/register"
                                            : `/${team}/register`
                                    }
                                >
                                    <Button color="teal">
                                        <Div txtWhite>{t("auth.register")}</Div>
                                    </Button>
                                </NavLink>
                                {settings.functionality.guestsActive && (
                                    <>
                                        <Button.Or />
                                        <NavLink
                                            to={`${
                                                team == null
                                                    ? "/guest/login"
                                                    : `/${team}/guest/login`
                                            }${
                                                features.tenanting.autoGuests
                                                    ? "?auto=true"
                                                    : ""
                                            }`}
                                        >
                                            <Button color="teal">
                                                <Div txtWhite>
                                                    {t("auth.guest")}
                                                </Div>
                                            </Button>
                                        </NavLink>
                                    </>
                                )}
                            </Button.Group>
                        </Div>
                    )}
                    <Divider />
                    {authPageLinks}
                </Div>
            </Div>
        </Div>
    );
    const hasLoggedIn = window.localStorage.getItem("firstLogin") != null;

    // ========================= Render Function =================================
    if (token != null)
        return (
            <AutoLogin
                {...{ token, setToken, team, branding, authPageLinks }}
            />
        );
    else if (
        team == null ||
        branding == null ||
        branding.cards == null ||
        branding.cards?.length === 0 ||
        features.general.noWelcome
    ) {
        return (
            <AuthLayout
                title={`Login to ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                {loginBox}
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout
                title={`Login to ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Div centered maxWd="360px">
                    <Carousel
                        lazyLoad
                        slidesToShow={1}
                        dots
                        initialSlide={
                            hasLoggedIn ? branding.cards?.length + 1 : 0
                        }
                        children={_.flatten([
                            <Div
                                superpad
                                fluid
                                flex
                                ht={`${wPageHt - 20}px`}
                                key="brand-splash"
                            >
                                <Image src={branding?.image} />
                            </Div>,
                            _.map(branding?.cards, (page, idx) => {
                                return (
                                    <Div
                                        fluid
                                        superpad
                                        big
                                        ht={`${wPageHt - 20}px`}
                                        key={`wpage-${idx}`}
                                        style={{ color: branding?.textColor }}
                                    >
                                        <Div rounded noOverflow gutter>
                                            <Image src={page.image} />
                                        </Div>
                                        <Div
                                            big
                                            bold
                                            gutter
                                            gapTop
                                            style={darkStyle(
                                                branding?.mainColor,
                                                "to top right"
                                            )}
                                            medpad
                                            rounded
                                        >
                                            {page.header}
                                        </Div>
                                        <Div trench>{page.content}</Div>
                                    </Div>
                                );
                            }),
                            loginBox,
                        ])}
                    />
                </Div>
            </AuthLayout>
        );
    }
}