import React, { useState, useEffect, useMemo } from "react";
import { Image } from "semantic-ui-react";
import { Div } from "@components/Generics.react";
import "@styles/components.scss";
import { gradient } from "@utilities/helpers";
import { avatarSchema, avatarMap } from "@schemas/avatarSchema";
import { createAvatar } from "@dicebear/core";
import { avataaars } from "@dicebear/collection";

export default function UserAvatar(props) {
    const { name, image, size, border, avatarConfig } = props;
    const [avatarData, setAvatarData] = useState(avatarConfig);

    useEffect(() => {
        if (avatarConfig != null) {
            setAvatarData(avatarConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarConfig]);

    const generateNumbers = (name) => {
        const numbers = [];

        while (numbers.length < 10) {
            const index = numbers.length % name.length;
            const charCode = name.charCodeAt(index);
            const number = charCode % 7;
            numbers.push(number);
        }

        return numbers;
    };

    // Generate a hash code from the text
    const hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    // Generate a unique light pastel color code based on the hash code
    const generateColorCode = (hashCode) => {
        const saturation = "70%";
        const lightness = "80%";
        const hue = hashCode % 360;
        return `hsl(${hue}, ${saturation}, ${lightness})`;
    };

    const colorCode = generateColorCode(hashCode(name));

    useEffect(() => {
        if (avatarData == null) {
            const numArray = generateNumbers(name);
            setAvatarData({
                background: colorCode,
                topType: avatarSchema.topType.male[numArray[0]],
                accessoriesType: avatarSchema.accessoriesType.male[numArray[1]],
                hairColor: avatarSchema.hairColor.male[numArray[2]],
                facialHairType: avatarSchema.facialHairType.male[numArray[3]],
                clotheType: avatarSchema.clotheType.male[numArray[4]],
                clotheColor: avatarSchema.clotheColor.male[numArray[5]],
                eyeType: avatarSchema.eyeType.male[numArray[6]],
                eyebrowType: avatarSchema.eyebrowType.male[numArray[7]],
                mouthType: avatarSchema.mouthType.male[numArray[8]],
                skinColor: avatarSchema.skinColor.male[numArray[9]],
            });
        }
    }, [avatarData, colorCode, name]);

    const avatar = useMemo(() => {
        const avConfig = {
            ...avatarMap(avatarData),
            size: 128,
        };
        return createAvatar(avataaars, avConfig).toDataUri();
    }, [avatarData]);

    // ========================= Render Function =================================
    return (
        <div
            style={{
                display: "inline-block",
                verticalAlign: "middle",
                height: `${size}px`,
                overflow: "hidden",
            }}
        >
            <Div
                centered
                pureCircle
                wd={`${size}px`}
                ht={`${size}px`}
                style={{ background: gradient(avatarData?.background) }}
            >
                <Image
                    src={image == null || image === "" ? avatar : image}
                    className="avatar-img"
                    style={{
                        width: `${size}px`,
                        height: `${size}px`,
                        border: `5px solid ${border}`,
                    }}
                />
            </Div>
        </div>
    );
}
