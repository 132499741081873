import _ from "lodash";
export const avatarLabels = [
    "Hair",
    "Accessories",
    "Hair Color",
    "Facial Hair",
    "Facial Hair Color",
    "Clothes",
    "Color Fabric",
    "Eyes",
    "Eyebrow",
    "Mouth",
    "Skin",
];
export const avatarSchema = {
    topType: {
        male: [
            "NoHair",
            "Eyepatch",
            "Hat",
            "Turban",
            "WinterHat1",
            "WinterHat2",
            "WinterHat3",
            "WinterHat4",
            "LongHairBun",
            "LongHairDreads",
            "ShortHairDreads01",
            "ShortHairDreads02",
            "ShortHairFrizzle",
            "ShortHairShaggyMullet",
            "ShortHairShortCurly",
            "ShortHairShortFlat",
            "ShortHairShortRound",
            "ShortHairShortWaved",
            "ShortHairSides",
            "ShortHairTheCaesar",
            "ShortHairTheCaesarSidePart",
        ],
        female: [
            "NoHair",
            "Hijab",
            "WinterHat1",
            "WinterHat2",
            "WinterHat3",
            "WinterHat4",
            "LongHairBigHair",
            "LongHairBob",
            "LongHairBun",
            "LongHairCurly",
            "LongHairCurvy",
            "LongHairDreads",
            "LongHairFrida",
            "LongHairFro",
            "LongHairFroBand",
            "LongHairNotTooLong",
            "LongHairShavedSides",
            "LongHairMiaWallace",
            "LongHairStraight",
            "LongHairStraight2",
            "LongHairStraightStrand",
            "ShortHairDreads01",
            "ShortHairDreads02",
            "ShortHairFrizzle",
            "ShortHairShaggyMullet",
            "ShortHairShortCurly",
            "ShortHairShortFlat",
            "ShortHairShortRound",
            "ShortHairShortWaved",
            "ShortHairSides",
            "ShortHairTheCaesar",
            "ShortHairTheCaesarSidePart",
        ],
    },
    accessoriesType: {
        male: [
            "Blank",
            "Kurt",
            "Prescription01",
            "Prescription02",
            "Round",
            "Sunglasses",
            "Wayfarers",
        ],
        female: [
            "Blank",
            "Kurt",
            "Prescription01",
            "Prescription02",
            "Round",
            "Sunglasses",
            "Wayfarers",
        ],
    },
    hairColor: {
        male: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "SilverGray",
        ],
        female: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "PastelPink",
            "Platinum",
            "Red",
            "SilverGray",
        ],
    },
    facialHairType: {
        male: [
            "Blank",
            "BeardMedium",
            "BeardLight",
            "BeardMajestic",
            "MoustacheFancy",
            "MoustacheMagnum",
        ],
        female: [],
    },
    facialHairColor: {
        male: [
            "Auburn",
            "Black",
            "Blonde",
            "BlondeGolden",
            "Brown",
            "BrownDark",
            "Platinum",
            "Red",
        ],
        female: [],
    },
    clotheType: {
        male: [
            "BlazerShirt",
            "BlazerSweater",
            "CollarSweater",
            "GraphicShirt",
            "Hoodie",
            "Overall",
            "ShirtCrewNeck",
            "ShirtScoopNeck",
            "ShirtVNeck",
        ],
        female: [
            "BlazerShirt",
            "BlazerSweater",
            "CollarSweater",
            "GraphicShirt",
            "Hoodie",
            "Overall",
            "ShirtCrewNeck",
            "ShirtScoopNeck",
            "ShirtVNeck",
        ],
    },
    clotheColor: {
        male: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
        ],
        female: [
            "Black",
            "Blue01",
            "Blue02",
            "Blue03",
            "Gray01",
            "Gray02",
            "Heather",
            "PastelBlue",
            "PastelGreen",
            "PastelOrange",
            "PastelRed",
            "PastelYellow",
            "Pink",
            "Red",
            "White",
        ],
    },
    eyeType: {
        male: [
            "Close",
            "Cry",
            "Default",
            "Dizzy",
            "EyeRoll",
            "Happy",
            "Hearts",
            "Side",
            "Squint",
            "Surprised",
            "Wink",
            "WinkWacky",
        ],
        female: [
            "Close",
            "Cry",
            "Default",
            "Dizzy",
            "EyeRoll",
            "Happy",
            "Hearts",
            "Side",
            "Squint",
            "Surprised",
            "Wink",
            "WinkWacky",
        ],
    },
    eyebrowType: {
        male: [
            "Angry",
            "AngryNatural",
            "Default",
            "DefaultNatural",
            "FlatNatural",
            "RaisedExcited",
            "RaisedExcitedNatural",
            "SadConcerned",
            "SadConcernedNatural",
            "UnibrowNatural",
            "UpDown",
            "UpDownNatural",
        ],
        female: [
            "Angry",
            "AngryNatural",
            "Default",
            "DefaultNatural",
            "FlatNatural",
            "RaisedExcited",
            "RaisedExcitedNatural",
            "SadConcerned",
            "SadConcernedNatural",
            "UnibrowNatural",
            "UpDown",
            "UpDownNatural",
        ],
    },
    mouthType: {
        male: [
            "Concerned",
            "Default",
            "Disbelief",
            "Eating",
            "Grimace",
            "Sad",
            "ScreamOpen",
            "Serious",
            "Smile",
            "Tongue",
            "Twinkle",
            "Vomit",
        ],
        female: [
            "Concerned",
            "Default",
            "Disbelief",
            "Eating",
            "Grimace",
            "Sad",
            "ScreamOpen",
            "Serious",
            "Smile",
            "Tongue",
            "Twinkle",
            "Vomit",
        ],
    },
    skinColor: {
        male: [
            "Tanned",
            "Yellow",
            "Pale",
            "Light",
            "Brown",
            "DarkBrown",
            "Black",
        ],
        female: [
            "Tanned",
            "Yellow",
            "Pale",
            "Light",
            "Brown",
            "DarkBrown",
            "Black",
        ],
    },
};

export const avatarMap = (avatarConfig) => {
    return {
        background: avatarConfig?.background || "#666666",
        top: [old2NewAvatar.topType[avatarConfig?.topType]],
        hairColor: [old2NewAvatar.hairColor[avatarConfig?.hairColor]],
        skinColor: [old2NewAvatar.skinColor[avatarConfig?.skinColor]],
        accessories: [
            old2NewAvatar.accessoriesType[avatarConfig?.accessoriesType],
        ],
        accessoriesColor: "000000",
        accessoriesProbability: 100,
        clothing: [old2NewAvatar.clotheType[avatarConfig?.clotheType]],
        clothesColor: [old2NewAvatar.clotheColor[avatarConfig?.clotheColor]],
        clothingGraphic: [_.sample(["bat", "bear", "deer", "skull"])],
        eyebrows: [old2NewAvatar.eyebrowType[avatarConfig?.eyebrowType]],
        eyes: [old2NewAvatar.eyeType[avatarConfig?.eyeType]],
        facialHair: [
            old2NewAvatar.facialHairType[avatarConfig?.facialHairType],
        ],
        facialHairColor: [
            old2NewAvatar.facialHairColor[avatarConfig?.facialHairColor],
        ],
        facialHairProbability: 100,
        mouth: [old2NewAvatar.mouthType[avatarConfig?.mouthType]],
    };
};

export const old2NewAvatar = {
    topType: {
        NoHair: "noHair",
        Hijab: "hijab",
        WinterHat1: "winterHat1",
        WinterHat2: "winterHat02",
        WinterHat3: "winterHat03",
        WinterHat4: "winterHat04",
        LongHairBigHair: "bigHair",
        LongHairBob: "bob",
        LongHairBun: "bun",
        LongHairCurly: "curly",
        LongHairCurvy: "curvy",
        LongHairDreads: "dreads",
        LongHairFrida: "frida",
        LongHairFro: "fro",
        LongHairFroBand: "froBand",
        LongHairNotTooLong: "longButNotTooLong",
        LongHairShavedSides: "shavedSides",
        LongHairMiaWallace: "miaWallace",
        LongHairStraight: "straight01",
        LongHairStraight2: "straight02",
        LongHairStraightStrand: "straightAndStrand",
        ShortHairDreads01: "dreads01",
        ShortHairDreads02: "dreads02",
        ShortHairFrizzle: "frizzle",
        ShortHairShaggyMullet: "shaggyMullet",
        ShortHairShortCurly: "shortCurly",
        ShortHairShortFlat: "shortFlat",
        ShortHairShortRound: "shortRound",
        ShortHairShortWaved: "shortWaved",
        ShortHairSides: "sides",
        ShortHairTheCaesar: "theCaesar",
        ShortHairTheCaesarSidePart: "theCaesarAndSidePart",
    },
    accessoriesType: {
        Blank: "",
        Kurt: "kurt",
        Prescription01: "prescription01",
        Prescription02: "prescription02",
        Round: "round",
        Sunglasses: "sunglasses",
        Wayfarers: "wayfarers",
    },
    hairColor: {
        Auburn: "4a312c",
        Black: "000000",
        Blonde: "b58143",
        BlondeGolden: "d6b370",
        Brown: "724133",
        BrownDark: "2c1b18",
        PastelPink: "f59797",
        Platinum: "e8e1e1",
        Red: "c93305",
        SilverGray: "ecdcbf",
    },
    skinColor: {
        Tanned: "edb98a",
        Yellow: "f825c",
        Pale: "ffdbb4",
        Light: "fd9841",
        Brown: "d08b5b",
        DarkBrown: "ae5d29",
        Black: "614335",
    },
    clotheType: {
        BlazerShirt: "blazerAndShirt",
        BlazerSweater: "blazerAndSweater",
        CollarSweater: "collarAndSweater",
        GraphicShirt: "graphicShirt",
        Hoodie: "hoodie",
        Overall: "overall",
        ShirtCrewNeck: "shirtCrewNeck",
        ShirtScoopNeck: "shirtScoopNeck",
        ShirtVNeck: "shirtVNeck",
    },
    clotheColor: {
        Black: "262e33",
        Blue01: "65c9ff",
        Blue02: "5199e4",
        Blue03: "25557c",
        Gray01: "3c4f5c",
        Gray02: "929598",
        Heather: "b6c0d2",
        PastelBlue: "b1e2ff",
        PastelGreen: "a7ffc4",
        PastelOrange: "ff5c5c",
        PastelRed: "ffafb9",
        PastelYellow: "ffffb1",
        Pink: "ff488e",
        Red: "ff0000",
        White: "ffffff",
    },
    eyebrowType: {
        Angry: "angry",
        AngryNatural: "angryNatural",
        Default: "default",
        DefaultNatural: "defaultNatural",
        FlatNatural: "flatNatural",
        RaisedExcited: "raisedExcited",
        RaisedExcitedNatural: "raisedExcitedNatural",
        SadConcerned: "sadConcerned",
        SadConcernedNatural: "sadConcernedNatural",
        UnibrowNatural: "unibrowNatural",
        UpDown: "upDown",
        UpDownNatural: "upDownNatural",
    },
    eyeType: {
        Close: "closed",
        Cry: "cry",
        Default: "default",
        Dizzy: "xDizzy",
        EyeRoll: "eyeRoll",
        Happy: "happy",
        Hearts: "hearts",
        Side: "side",
        Squint: "squint",
        Surprised: "surprised",
        Wink: "wink",
        WinkWacky: "winkWacky",
    },
    facialHairType: {
        Blank: "blank",
        BeardMedium: "beardMedium",
        BeardLight: "beardLight",
        BeardMajestic: "beardMajestic",
        MoustacheFancy: "moustacheFancy",
        MoustacheMagnum: "moustacheMagnum",
    },
    facialHairColor: {
        Auburn: "4a312c",
        Black: "000000",
        Blonde: "b58143",
        BlondeGolden: "d6b370",
        Brown: "724133",
        BrownDark: "2c1b18",
        PastelPink: "f59797",
        Platinum: "e8e1e1",
        Red: "c93305",
        SilverGray: "ecdcbf",
    },
    mouthType: {
        Concerned: "concerned",
        Default: "default",
        Disbelief: "disbelief",
        Eating: "eating",
        Grimace: "grimace",
        Sad: "sad",
        ScreamOpen: "screamOpen",
        Serious: "serious",
        Smile: "smile",
        Tongue: "tongue",
        Twinkle: "twinkle",
        Vomit: "vomit",
    },
};
