export const updateDeck = (readerType, currentState, attempt) => {
    const presentState = {
        viewedPercentage: Math.min(100, currentState.viewedPercentage ?? 0),
        viewAttempts: currentState.viewAttempts ?? 0,
        timeSpent: currentState.timeSpent ?? 0,
        score: currentState.score,
        points: currentState.points ?? 0,
        completion: currentState.completion ?? false,
        completionDate: currentState.completionDate,
    };
    const newState = processState(presentState, attempt, readerType);

    const response = {
        viewedPercentage: newState.viewedPercentage ?? 0,
        viewAttempts: newState.viewAttempts ?? 0,
        timeSpent: newState.timeSpent ?? 0,
        score: newState.score,
        points: newState.points ?? 0,
        completion: newState.completion ?? false,
        completionDate: newState.completionDate,
    };

    return response;
};

function processState(presentState, attempt, readerType) {
    switch (readerType) {
        case "htmldeck":
        case "article":
            return updateHTMLDeck(presentState, attempt);
        case "badgedeck":
        case "badge":
            return updateBadge(presentState, attempt);
        case "certdeck":
        case "certificate":
            return updateCertificate(presentState, attempt);
        case "rewarddeck":
        case "reward":
            return updateReward(presentState, attempt);
        case "docdeck":
        case "document":
            return updateDocument(presentState, attempt);
        case "videck":
        case "video":
            return updateVideo(presentState, attempt);
        case "assessment":
            return updateAssessment(presentState, attempt);
        case "survey":
            return updateSurvey(presentState, attempt);
        case "game":
            return updateGame(presentState, attempt);
        case "scorm":
        case "scormdeck":
        case "scormexam":
            return updateSCORM(presentState, attempt);
        case "qdf":
        case "story":
            return updateQdf(presentState, attempt);
        case "gallery":
        case "gallerydeck":
            return updateGallery(presentState, attempt);
        case "productcard":
        case "productcarddeck":
            return updateProductCard(presentState, attempt);
        case "productgrid":
        case "productgriddeck":
            return updateProductGrid(presentState, attempt);
        case "news":
        case "newsdeck":
            return updateNews(presentState, attempt);
        case "faqdeck":
        case "faq":
            return updateFAQ(presentState, attempt);
        case "infodeck":
        case "infographic":
            return updateInfo(presentState, attempt);
        case "eventdeck":
        case "event":
            return updateEvent(presentState, attempt);
        case "course":
            return updateCourse(presentState, attempt);
        case "webinar":
        case "webinardeck":
            return updateWebinar(presentState, attempt);
        case "meme":
        case "memedeck":
            return updateMeme(presentState, attempt);
        case "productfaq":
        case "productfaqdeck":
            return updateProductFAQ(presentState, attempt);
        case "productcatalog":
        case "productcatalogdeck":
            return updateProductCatalog(presentState, attempt);
        case "playlist":
        case "playlistdeck":
            return updatePlaylist(presentState, attempt);
        case "podcast":
        case "podcastdeck":
            return updatePodcast(presentState, attempt);
        case "checklist":
        case "checklistdeck":
            return updateChecklist(presentState, attempt);
        case "calculator":
        case "calculatordeck":
            return updateCalculator(presentState, attempt);
        case "downloadable":
        case "downloadabledeck":
            return updateDownloadable(presentState, attempt);
        case "scratchcard":
        case "scratchcarddeck":
            return updateScratchcard(presentState, attempt);
        case "guidelines":
        case "guidelinesdeck":
            return updateGuidelines(presentState, attempt);
        case "iframe":
        case "iframedeck":
            return updateIframe(presentState, attempt);
        case "poll":
        case "polldeck":
            return updatePoll(presentState, attempt);
        case "rating":
        case "ratingdeck":
            return updateRating(presentState, attempt);
        case "discussion":
        case "discussiondeck":
            return updateDiscussion(presentState, attempt);
        case "recommendation":
        case "recommendationdeck":
            return updateRecommendation(presentState, attempt);
        case "profiler":
        case "profilerdeck":
            return updateProfiler(presentState, attempt);
        case "reel":
        case "reeldeck":
            return updateReel(presentState, attempt);
        case "leaderboard":
        case "leaderboarddeck":
            return updateLeaderboard(presentState, attempt);
        case "flashcards":
        case "flashcardsdeck":
            return updateFlashCards(presentState, attempt);
        case "offer":
        case "offerdeck":
            return updateOffer(presentState, attempt);
        case "challenge":
        case "challengedeck":
            return updateChallenge(presentState, attempt);
        default:
            return { error: true };
    }
}

const updateChallenge = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};

const updateOffer = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateFlashCards = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateCalculator = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateLeaderboard = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateBadge = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateCertificate = (currentState, attempt) => {
    const { completion, timeSpent, pointsDelta, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateReward = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDocument = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateVideo = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateSCORM = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        score,
        pointsDelta,
    } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateQdf = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateAssessment = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        score,
        pointsDelta,
    } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts:
            viewedPercentage > 0
                ? currentState.viewAttempts + 1
                : currentState.viewAttempts, // To handle cancel from the start screen of the assessment
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score || 0),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGame = (currentState, attempt) => {
    const {
        viewedPercentage,
        completion,
        timeSpent,
        date,
        pointsDelta,
        score,
    } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: Math.max(currentState.score || 0, score || 0),
        points: currentState.points + (pointsDelta ?? 0),
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateSurvey = (currentState, attempt) => {
    const { viewedPercentage, completion, timeSpent, date } = attempt;
    return {
        viewedPercentage:
            Math.min(
                100,
                Math.max(currentState.viewedPercentage || 0, viewedPercentage)
            ) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateHTMLDeck = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGallery = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateChecklist = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateCourse = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateFAQ = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateInfo = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateMeme = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePlaylist = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePodcast = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductCatalog = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductCard = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductFAQ = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateWebinar = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDownloadable = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateScratchcard = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateGuidelines = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateEvent = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateNews = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateIframe = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updatePoll = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateRating = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateDiscussion = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateRecommendation = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProfiler = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateReel = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
const updateProductGrid = (currentState, attempt) => {
    const { completion, timeSpent, date } = attempt;
    return {
        viewedPercentage: (completion ? 100 : 0) || 0,
        viewAttempts: currentState.viewAttempts + 1,
        timeSpent: currentState.timeSpent + (timeSpent ?? 0),
        score: null,
        points: 0,
        completion: completion || currentState.completion,
        completionDate: currentState.completionDate || (completion && date),
    };
};
