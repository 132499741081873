// Import from NPM
// -------------------------------------
import React, { useState } from "react";
import axios from "axios";

import _ from "lodash";
import { Input, Button, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------

export default function OTPLogin(props) {
    const { loginAction, setError } = props;
    const [phone, setPhone] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [enteredOTP, setEnteredOTP] = useState("");
    const [attemptsOver, setAttemptsOver] = useState(false);
    const { t } = useTranslation("common");
    const [resendCountdown, setResendCountdown] = useState(60);
    const [canResend, setCanResend] = useState(false);

    const startResendCountdown = () => {
        setCanResend(false);
        setResendCountdown(60);
        const interval = setInterval(() => {
            setResendCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(interval);
                    setCanResend(true);
                    return 60;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const sendOtp = async () => {
        try {
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/otp/generate/${phone}`,
            });
            if (response.status) {
                setOtpSent(true);
                startResendCountdown();
            }
        } catch (error) {
            setError(error.response?.data?.message);
            if (error.response?.data?.errorCode === 3) {
                setAttemptsOver(true);
            }
        }
    };

    const resendOtp = async () => {
        if (canResend) {
            await sendOtp();
        }
    };

    const verifyOtp = async (otp) => {
        try {
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/otp/verify/${phone}/${otp}`,
            });
            loginAction(response);
        } catch (error) {
            setError(error.response?.data?.message);
            setEnteredOTP("");
            if (error.response?.data?.errorCode === 3) {
                setAttemptsOver(true);
            }
        }
    };

    const handleOtpChange = (e, index) => {
        const value = e.target.value;
        if (/^\d$/.test(value)) {
            const newOtp = enteredOTP.split("");
            newOtp[index] = value;
            const updatedOtp = newOtp.join("");
            setEnteredOTP(updatedOtp);
            if (index < otpLength - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            } else {
                verifyOtp(updatedOtp);
            }
        }
    };

    const getNumberBox = (
        <>
            <Div massive altText fluid center-align gutter>
                {t("auth.loginPage.otpLabel")}
            </Div>
            <Div fluid gutter>
                <Input
                    fluid
                    label={t("auth.loginPage.phoneLabel")}
                    placeholder={t("auth.loginPage.phonePlaceholder")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Div>
            <Div gutter>
                <Button
                    fluid
                    primary
                    content={t("auth.loginPage.otpSend")}
                    disabled={phone.length !== 10}
                    onClick={sendOtp}
                />
            </Div>
        </>
    );
    const otpLength = settings.auth.otpLength;
    const enterOtpBox = (
        <>
            <Div massive altText fluid center-align gutter>
                {t("auth.loginPage.otpVerifyLabel")}
            </Div>
            <Div fluid gutter large flex gapped>
                {enteredOTP.length === otpLength ? (
                    <Loader active inline="centered" />
                ) : (
                    _.times(otpLength, (n) => (
                        <Div wd={`calc(${100/otpLength}% - 7.5px)`} center-align key={n}>
                            <Input fluid>
                                <input
                                    id={`otp-input-${n}`}
                                    maxLength={1}
                                    onChange={(e) => handleOtpChange(e, n)}
                                    style={{ textAlign: "center", padding:"4px 8px" }}
                                />
                            </Input>
                        </Div>
                    ))
                )}
            </Div>
            <Div gutter>
                <Button
                    fluid
                    secondary
                    content={
                        canResend
                            ? t("auth.loginPage.resendOtp")
                            : `${t(
                                  "auth.loginPage.resendOtp"
                              )} (${resendCountdown}s)`
                    }
                    disabled={!canResend}
                    onClick={resendOtp}
                />
            </Div>
        </>
    );
    // ========================= Render Function =================================
    return attemptsOver ? <div /> : otpSent ? enterOtpBox : getNumberBox;
}
